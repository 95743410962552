<template>
  <div>
    <div class="suk_right">
      <div>
        <div class="title">基础信息</div>
        <!-- 动态 -->
        <div class="suk">
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>商品类型
            </label>
            <div class="input-inline">
              <cus-select
                :list="type_list_inp"
                :value="type_id"
                v-if="is_edit"
                @change="changeinput($event, 'type_id')"
              ></cus-select>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>宝贝标题
            </label>
            <div class="input-block">
              <cus-input
                class="input inp"
                :placeholder="'请输入商品标题'"
                :value="title"
                v-if="is_edit"
                @chenginput="changeinput($event, 'title')"
                :max_num="100"
              ></cus-input>
            </div>
          </div>
          <div class="form-item">
            <div class="item-inline">
              <label class="form-label"> <span class="red">*</span>品牌 </label>
              <div class="input-inline">
                <cus-input
                  class="input inp"
                  :placeholder="'请输入'"
                  :value="pinpai"
                  v-if="is_edit"
                  @chenginput="changeinput($event, 'pinpai')"
                ></cus-input>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="item-inline">
              <label class="form-label">
                <span class="red">*</span>搜索关键字
              </label>
              <div
                class="input-block nature"
                v-for="(item, index) in keyword"
                :key="index"
              >
                <div class="input-inline">
                  <el-input
                    class=""
                    :placeholder="'请输入'"
                    v-model="keyword[index]"
                  ></el-input>
                </div>
                <div
                  class="input-icon iconfont icon-shanchu"
                  @click="del_keyword(index)"
                  v-if="keyword.length > 1"
                ></div>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="input-block">
              <div class="addbtn" @click="add_keyword">+ 添加关键字</div>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>自定义商品属性
            </label>
            <div class="input-block nature">
              <div class="input-inline">例如:黑色XXL</div>
              <div class="input-inline xinline">
                <div>价格</div>
              </div>
              <div class="input-inline xinline">
                <div>预售价</div>
              </div>
              <div class="input-inline xinline">
                <div>数量</div>
              </div>
               <div class="input-inline xinline">
                <div>商品图片</div>
              </div>
            </div>
            <template v-if="is_edit">
              <div
                class="input-block nature"
                v-for="(item, index) in params"
                :key="index"
              >
                <div class="input-inline">
                  <cus-input
                    class="input inp"
                    :value="item.title"
                    :placeholder="'请输入属性值'"
                    @chenginput="chainp($event, 'title', index)"
                  ></cus-input>
                </div>
                <div class="input-inline xinline">
                  <cus-input
                    class="input inp"
                    :value="item.price"
                    :placeholder="'价格'"
                    @chenginput="chainp($event, 'price', index)"
                  ></cus-input>
                </div>
                <div class="input-inline xinline">
                  <cus-input
                    class="input inp"
                    :text="'number'"
                    :value="item.sale"
                    :placeholder="'预售价'"
                    @chenginput="chainp($event, 'sale', index)"
                  ></cus-input>
                </div>
                <div class="input-inline xinline">
                  <cus-input
                    class="input inp"
                    :text="'number'"
                    :value="item.total"
                    :placeholder="'数量'"
                    @chenginput="chainp($event, 'total', index)"
                  ></cus-input>
                </div>
                <div class="input-inline xinline">
                  <cus-upload
                    class="img_upload"
                    :src="item.img_src"
                    @change="chainp($event, 'img_src', index)"
                  ></cus-upload>
                </div>
                <div
                  class="input-icon iconfont icon-shanchu"
                  v-if="params.length > 1"
                  @click="del_nature(index)"
                ></div>
              </div>
            </template>
          </div>
          <div class="form-item">
            <div class="input-block">
              <div class="addbtn" @click="add_nature">+ 添加属性</div>
            </div>
          </div>
          
        </div>
        <!-- 静态 -->
        <div class="suk1">
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>上架时间
            </label>
            <div class="input-block radio">
              <el-radio class="item" v-model="putaway" :label="0">
                立刻上架
              </el-radio>
              <el-radio class="item" v-model="putaway" :label="1">
                预售上架
              </el-radio>
              <el-radio class="item" v-model="putaway" :label="2">
                放入仓库
              </el-radio>
            </div>
          </div>
          <div class="form-item" v-if="putaway == 1">
            <div class="input-block radio">
              <div>
                设定至
                <!-- <el-date-picker v-model="timing" type="datetime" placeholder="选择日期时间"
									format="yyyy-MM-dd HH:mm" @change="dataSearch">
								</el-date-picker> -->
                <el-date-picker
                  v-model="act_start_end_time"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetimerange"
                  format="yyyy-MM-dd HH:mm:ss"
                  range-separator="——"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>店铺活动
            </label>
            <div class="input-block radio">
              <el-checkbox class="item" v-model="reduction"> 满减 </el-checkbox>
            </div>
            <div class="input-block radio">
              <el-checkbox class="item"> 优惠券 </el-checkbox>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>售后服务
            </label>
            <el-checkbox-group v-model="checkedService">
              <div class="input-block radio">
                <el-checkbox class="item" label="1"> 提供电子发票 </el-checkbox>
              </div>
              <div class="input-block radio">
                <el-checkbox class="item" label="2"> 保修服务 </el-checkbox>
              </div>
              <div class="input-block radio">
                <el-checkbox class="item" label="3">
                  承诺退换
                  <span style="color: #999999; font-size: 12px">
                    凡使用支付宝服务付款购买本店商品，若存在质量问题或与描述不符，本店将主动提供退换货服务并承担来回邮费
                  </span>
                </el-checkbox>
              </div>
              <div class="input-block radio">
                <el-checkbox class="item" label="4">
                  承诺服务
                  <span style="color: #a466fd; font-size: 12px"
                    >该商品，必须支持《七天退货》服务
                  </span>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>提取方式
            </label>
            <div class="input-block radio">
              <el-radio class="item" v-model="express" :label="0">
                卖家承担费用
              </el-radio>
            </div>
            <div class="input-block radio">
              <el-radio class="item" v-model="express" :label="1">
                买家承担运费
              </el-radio>
            </div>
          </div>
          <!-- <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>付款方式
            </label>
            <div class="input-block radio">
              <el-radio class="item" v-model="payment" label="0">
                一口价
              </el-radio>
              <el-radio class="item" v-model="payment" label="1">
                预售模式
              </el-radio>    
            </div>    
          </div>     -->
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>库存计数
            </label>
            <div class="input-block radio">
              <el-radio class="item" v-model="Inventory_count" :label="0">
                买家拍下减库存
              </el-radio>
              <el-radio class="item" v-model="Inventory_count" :label="1">
                买家付款减库存
              </el-radio>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <h3>商品图片</h3>
            </label>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>宝贝图片
            </label>
            <div class="input-block radio">
              <span class="item" style="color: #999999; font-size: 12px"
                >宝贝主图大小不能超过3MB;700*700以上图片上传后宝贝详情自动提供放大功能。</span
              >
            </div>
          </div>
          <div class="form-item">
            <div class="input-block radio" style="margin-left: 80px">
              <div class="input-img">
                <div class="img_title"><span class="red">*</span>主图</div>
                <cus-upload
                  class="img_upload"
                  :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                  :src="img_zhu"
                  v-if="is_edit"
                  @change="cha_img($event, 'img_zhu')"
                ></cus-upload>
              </div>
              <template v-if="is_edit">
                <div class="input-img">
                  <cus-upload
                    class="img_upload"
                    :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                    :src="img_list[0]"
                    @change="cha_imgli($event, 0)"
                  ></cus-upload>
                </div>
                <div class="input-img">
                  <cus-upload
                    class="img_upload"
                    :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                    :src="img_list[1]"
                    @change="cha_imgli($event, 1)"
                  ></cus-upload>
                </div>
                <div class="input-img">
                  <cus-upload
                    class="img_upload"
                    :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                    :src="img_list[2]"
                    @change="cha_imgli($event, 2)"
                  ></cus-upload>
                </div>
                <div class="input-img">
                  <cus-upload
                    class="img_upload"
                    :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                    :src="img_list[3]"
                    @change="cha_imgli($event, 3)"
                  ></cus-upload>
                </div>
                <div class="input-img">
                  <cus-upload
                    class="img_upload"
                    :accept="'image/png,image/gif,image/jpg,image/jpeg'"
                    :src="img_list[4]"
                    @change="cha_imgli($event, 4)"
                  ></cus-upload>
                </div>
              </template>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label"> 主图视频 </label>
            <div class="input-block radio">
              <span class="item" style="color: #999999; font-size: 12px"
                >视频支持1:1，16:9，时长建议15s，最长不超过60s</span
              >
            </div>
          </div>
          <div class="form-item">
            <div class="input-block radio flex" style="margin-left: 80px">
              <div class="input-img" style="height: auto">
                <cus-upload-video
                  class="img_upload"
                  @change="cha_vdo"
                  :src="video_imgsrc"
                  :vosrc="video_src"
                ></cus-upload-video>
              </div>
            </div>
          </div>
          <div class="form-item">
            <label class="form-label">
              <span class="red">*</span>文本编辑
            </label>
          </div>
          <div class="form-item">
            <div class="input-block" style="margin-left: 80px">
              <quill-editor
                :options="editorOption"
                v-model="goods_introduce"
              ></quill-editor>
            </div>
          </div>
          <div class="submit">
            <div class="backbtn" @click="back" style="">返回列表</div>
            <div class="btn" @click="sub_btn">提交宝贝信息</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CusInput from "../from/cus-input.vue";
import axios from "../../common/axios";
import CusUpload from "../from/cus-upload.vue";
import CusUploadVideo from "../from/cus-upload-video.vue";
import CusSelect from "../from/cus-select.vue";

export default {
  components: {
    CusInput,
    CusUpload,
    CusSelect,
    CusUploadVideo,
  },
  data() {
    return {
      act_start_end_time: [],
      timing: "", //定时
      end_time: "",
      qrtiming: "", //定时
      express: 0, //提取方式
      payment: 0, //付款方式
      putaway: 0, //上架选中的值
      Inventory_count: 0, //库存计数
      goods_introduce: "", //富文本编辑器内容
      reduction: true,
      checkedService: [],
      pinpai: "",
      value: "",
      type_list_inp: [],
      type_id: 0,
      params: [
        {
          title: "",
          price: "",
          total: "",
          img_src: "",
        },
      ],
      title: "",
      img_zhu: "",
      img_list: [],
      video_src: "",
      video_imgsrc: "",
      is_edit: false,
      keyword: [""], //关键字列表
      editorOption: {
        placeholder: "Hello World",
        modules: {
          toolbar: {
            container: [
              [
                {
                  size: ["small", false, "large"],
                },
              ],
              [
                {
                  color: [],
                },
                {
                  background: [],
                },
              ],
              [
                {
                  font: [],
                },
              ],
              [
                {
                  align: [],
                },
              ],
              ["bold", "italic"],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ],
              ["link", "image"],
              [
                {
                  direction: "rtl",
                },
              ],
            ],
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false,
          },
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
      goods_id: 0,
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (!(parseInt(id) > 0)) {
      this.back();
      return;
    }
    this.goods_id = id;
    this.get_type_inp();
    this.get_goods(id);
  },
  methods: {
     add_keyword() {
      this.keyword.push("");
    },
    del_keyword(index) {
      this.keyword.splice(index, 1);
    },
    get_goods(id) {
      let params = [
        {
          title: "",
          price: "",
          total: "",
          sale: "",
          img_src: "",
        },
      ];
      let Inventory_count = 0;
      let checkedService = [];
      let express = 0;
      let goods_introduce = "";
      let img_zhu = "";
      let img_list = [];
      let pinpai = "";
      let putaway = 0;
      let reduction = false;
      let timing = "";
      let title = "";
      let end_time = "";
      let type_id = 0;
      let video_imgsrc = "";
      let video_src = "";

      this.is_edit = false;
      let url = "/adminshop/goods/get_goods.html";
      let data = { id };
      axios.post(url, data).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          params = data.params;
          Inventory_count = data.Inventory_count;
          checkedService = data.checkedService;
          express = data.express;
          goods_introduce = data.goods_introduce;
          img_zhu = data.img_zhu;
          img_list = data.img_list;
          pinpai = data.pinpai;
          putaway = data.putaway;
          reduction = data.reduction;
          timing = data.timing;
          end_time = data.etime;
          title = data.title;
          type_id = data.type_id;
          video_imgsrc = data.video_imgsrc;
          video_src = data.video_src;
          this.keyword = data.keyword
        } else {
          console.log(res.error);
        }
        console.log(params);
        this.params = params;
        this.Inventory_count = Inventory_count;
        this.checkedService = checkedService;
        this.express = express;
        this.goods_introduce = goods_introduce;
        this.img_zhu = img_zhu;
        this.img_list = img_list;
        this.pinpai = pinpai;
        this.putaway = putaway;
        this.reduction = reduction;
        this.timing = timing;
        this.end_time = end_time;
        this.title = title;
        this.type_id = type_id;
        this.video_imgsrc = video_imgsrc;
        this.video_src = video_src;
        this.is_edit = true;
        this.act_start_end_time[0] = timing;
        this.act_start_end_time[1] = end_time;
        console.log(this.act_start_end_time + "1111111");
      });
    },
    //获取input的value值
    back() {
      this.$router.go(-1);
    },
    changeinput(e, name) {
      this[name] = e.value;
    },
    //添加属性值
    add_nature() {
      this.params.push({
        title: "",
        price: "",
        total: "",
        sale: "",
        img_src: "",
      });
    },
    sub_btn() {
      let start = "";
      let end = "";
      if (
        this.act_start_end_time != null &&
        this.act_start_end_time.length > 0
      ) {
        start = this.act_start_end_time[0];
        end = this.act_start_end_time[1];
        console.log(start, end);
      }
      let {
        type_id,
        title,
        pinpai,
        params,
        putaway,
        reduction,
        checkedService,
        express,
        Inventory_count,
        img_zhu,
        img_list,
        video_src,
        video_imgsrc,
        goods_introduce,
      } = this;
      // console.log(type_id,title,pinpai,params,putaway,qrtiming,reduction,checkedService,express,Inventory_count,img_zhu,img_list,video_src,video_imgsrc,goods_introduce);

      let id = this.goods_id;

      let url = "/adminshop/goods/edit_goods.html";
      let data = {
        id,
        type_id,
        title,
        pinpai,
        params,
        putaway,
        qrtiming: start,
        end_time: end,
        reduction,
        checkedService,
        express,
        Inventory_count,
        img_zhu,
        img_list,
        video_src,
        video_imgsrc,
        goods_introduce,
        keyword:this.keyword
      };

      axios.post(url, data).then((res) => {
        console.log(res);
        let data = res.data;
        if (res.success) {
          if (data.status == 1) {
            // console.log(data);
            this.$message({
              message: data.msg,
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/goods/mygoods",
              });
            }, 1200);
          } else {
            this.$alert(data.msg, {
              title: "提示",
              confirmButtonText: "确定",
              type: "error",
            });
          }
        }
      });
    },
    del_nature(index) {
      let list = this.params;

      this.$confirm("此操作将删除这个商品属性, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.params = [];
          list.splice(index, 1);
          this.$nextTick(() => {
            this.params = list;
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    chainp(e, name, index) {
      let value = e.value;
      // console.log(value,name,index)
      this.params[index][name] = value;
      // console.log(this.params)
    },
    cha_img(e, index) {
      // console.log(e,index);
      this[index] = e.value;
    },
    cha_vdo(e) {
      // console.log(e);
      this.video_src = e.value;
      this.video_imgsrc = e.img_src;
    },
    cha_imgli(e, index) {
      this.img_list[index] = e.value;
    },
    // dataSearch(data) {
    // 	if (data == null || data == undefined || data == "") {
    // 		this.qrtiming = "";
    // 		return;
    // 	}
    // 	var yearn = data.getFullYear();
    // 	var monthn = data.getMonth() + 1;
    // 	var dayn = data.getDate();
    // 	var h = data.getHours();
    // 	var m = data.getMinutes();
    // 	if (monthn < 10) {
    // 		monthn = "0" + monthn;
    // 	}
    // 	if (dayn < 10) {
    // 		dayn = "0" + dayn;
    // 	}
    // 	if (h < 10) {
    // 		h = "0" + h;
    // 	}
    // 	if (m < 10) {
    // 		m = "0" + m;
    // 	}
    // 	this.qrtiming =
    // 		yearn + "-" + monthn + "-" + dayn + " " + h + ":" + m + ":00";
    // },
    //获取类型属性
    get_type_inp() {
      this.type_list_inp = [];
      let type_list = [];
      let that = this;

      let url = "/adminshop/type/index.html";

      axios.post(url).then((res) => {
        let success = res.success;
        let data = res.data;
        if (success) {
          let list = data.list;
          if (list != undefined && list != null && list != "") {
            type_list = list;
          }
        } else {
          console.log(res.error);
        }
        that.$nextTick(() => {
          that.type_list_inp = type_list;
        });
      });
    },
  },
};
</script>

<style>
@import "./select_class.css";
</style>
